import $ from 'jquery';
import 'select2';

const select2 = () => {
  // Initialisation de select2 pour les éléments dans le contexte spécifié
  document.querySelectorAll('.select2').forEach(function(selectElement) {
    if (selectElement) { // Vérification que selectElement n'est pas null
      let dropdownParent = null;
      
      // Vérification que selectElement est dans le DOM et a un parent modal
      const modalParent = selectElement.closest('.modal');
      if (modalParent && modalParent.classList.contains('show')) {
        dropdownParent = modalParent;
      }

      const options = {
        theme: "bootstrap-5",
      };

      if (dropdownParent) {
        options.dropdownParent = dropdownParent;
      }

      $(selectElement).select2(options);
    }
  });
};

const setupModalListeners = () => {
  // Écouteur d'événement pour l'affichage des modals
  document.querySelectorAll('.modal').forEach(function(modal) {
    if (modal) { // Vérification que modal n'est pas null
      modal.addEventListener('shown.bs.modal', function() {
        select2(modal); // Initialise select2 uniquement dans ce modal
      });
    }
  });
};

document.addEventListener('DOMContentLoaded', function() {
  // Initialisation pour les éléments hors modal
  select2(document);

  // Configuration des écouteurs pour les modals
  setupModalListeners();
});

export { select2, setupModalListeners };
