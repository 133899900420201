import { Popover } from 'bootstrap';

const popover = () => {
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl, {
      trigger: 'hover'
    })
  })
};

export { popover };