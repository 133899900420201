const submitFormAjax = () => {
  const forms = document.querySelectorAll('.js-submit-form-ajax');
  
  forms.forEach(form => {
    form.addEventListener('change', (event) => {
      event.preventDefault(); // Empêcher la soumission normale du formulaire
      
      const formId = form.id;
      const method = form.method.toUpperCase();

      submitForm(method, formId);
    });
  });
}


const submitForm = (method, formId) => {
  const form = document.getElementById(formId);

  // Effectuer la requête Ajax
  fetch(form.action, {
    method: method,
    body: new FormData(form),
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Accept': 'application/javascript'
    }
  })
  .then(response => {
    if (response.ok) {
      return response.text();
    }
    throw new Error(`Erreur HTTP : ${response.status}`);
  })
  .then(data => {
    try {
      eval(data); // Attention : Toujours s'assurer que la réponse est sûre à exécuter.
    } catch (e) {
      console.error('Erreur lors de l\'exécution du script retourné :', e);
    }
  })
  .catch(error => {
    console.error('Erreur lors de la soumission Ajax :', error);
  });
}

export { submitFormAjax };
